import React, { Fragment, useState } from "react";

import { generatePath, useNavigate, useParams } from "react-router-dom";

import ErrorImage from "assets/images/FTUX/not-found-graphic.png";

import { DetailsBar } from "components/DetailsBar/DetailsBar";
import { DetailsPane } from "components/DetailsPane/DetailsPane";
import {
  getAppUserInfoElements,
  getClockIcon,
  getDetails,
  getExtensionsDropdown,
  getIntegrationDropdown,
  GroupedInfoElement,
  GroupedTicketDetailsElement,
  InfoElement,
  IntegrationDropdownProps,
  isElementDisabled,
  readMetadata,
} from "components/DetailsPane/helper";
import EmptyStateElement from "components/EmptyStateElement/components/EmptyStateElement";
import Heading from "components/Heading/Heading";
import EditableTitle from "components/Input/EditableTitle/EditableTitle";
import InputWithTags from "components/Input/InputWithTags/InputWithTags";
import { RowType } from "components/MasterTable/models/MasterTableModel";
import PageWrap from "components/PageWrap/PageWrap";
import { Spinner } from "components/Spinner/Spinner";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useFeedbackAttributes } from "hooks/filtering/modules/useFeedbackAttributes";
import { useCustomElementInjection } from "hooks/useCustomElementInjection";

import NavigationCustomElement from "layouts/components/PageNavigation/NavigationCustomElement/NavigationCustomElement";
import { ELEMENT_CONTAINER_ID, NAV_LIST } from "layouts/components/PageNavigation/PageNavigation";

import { getIntegrationTypeName, Integration } from "models/integrations";
import { Issue, IssueServiceHookPermalink } from "models/issueTracking";

import AttachmentsContainer from "pages/shared/components/Attachments/AttachmentsContainer";
import LinkedTicketsTable from "pages/shared/components/LinkedTicketsTable/LinkedTicketsTable";
import { TicketDetailsContainer } from "pages/shared/components/TicketDetails/TicketDetailsContainer";
import { TicketContainer } from "pages/shared/components/TicketDetails/TicketDetailsContainer.styles";
import { TicketInfoContainer } from "pages/shared/components/TicketDetails/TicketInfoContainer";
// will be added later
/* import { NavigationTitle } from "pages/UserFeedback/views/UserFeedback.styles"; */

import { RoutePaths } from "router/config/routePaths";

import { docLink } from "util/docs";
import identifiers from "util/identifiers.json";

import FullstoryIcon from "../../../assets/images/integrations/fullstory-icon.svg";
import useCommonTagsApiConsumer from "../../../consumers/useCommonTagsApiConsumer";
import CustomFields from "../../shared/components/CustomFields/CustomFields";
import MessageContainer from "../../shared/components/Messages/MessageContainer";
import * as Styled from "../../shared/styles/DetailsWrapper.styles";
import SmartSuggestion from "../components/SmartSuggestion/SmartSuggestion";
import useUserFeedbackDetailsApiConsumer from "../consumers/useUserFeedbackDetailsApiConsumer";
import useUserFeedbackMessagesConsumer from "../consumers/useUserFeedbackMessagesConsumer";

export const UserFeedbackDetailsPageView = () => {
  const params = useParams<{ userFeedbackKey: string }>();
  const navigate = useNavigate();

  const { addExternal } = useFeedbackAttributes(true);
  const [refreshActivity, setRefreshActivity] = useState(false);

  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const consumer = useUserFeedbackDetailsApiConsumer({
    selectedWorkspaceId: selectedWorkspace?.id,
    selectedAppId: selectedApp?.id,
    userFeedbackKey: params.userFeedbackKey,
    setRefreshActivity: setRefreshActivity,
  });
  const { messages, sendMessage, deleteMessage, editMessage } = useUserFeedbackMessagesConsumer({
    selectedWorkspaceId: selectedWorkspace?.id,
    selectedAppId: selectedApp?.id,
    userFeedbackId: consumer.userFeedbackDetails?.id,
    activityRefresh: refreshActivity,
    setRefreshActivity: setRefreshActivity,
  });

  const { commonTags } = useCommonTagsApiConsumer({ selectedWorkspaceId: selectedWorkspace?.id });
  const [detailDropdownOpened, setDetailDropdownOpened] = useState<string | undefined>(undefined);

  const CustomElement = consumer.userFeedbackDetails ? (
    // will be added later

    /*consumer.userFeedbackDetails.parent_id ? (
      <Fragment>
        <NavigationCustomElement>
          <NavigationTitle
            $isButton
            onClick={() =>
              navigate(
                generatePath(RoutePaths.USER_FEEDBACK_DETAILS, {
                  workspaceSlug: selectedWorkspace?.slug,
                  appKey: selectedApp?.key,
                  userFeedbackKey: params.userFeedbackKey,
                }),
              )
            }
          >
            <div>
              <p>{consumer.userFeedbackDetails?.title ?? "No description"}</p>
            </div>
          </NavigationTitle>
          <NavigationTitle $isButton={false}>
            <Styled.DetailsTitle data-testid={identifiers["navbar.detailsTitle"]}>
              <p>{consumer.userFeedbackDetails?.pretty_title}</p>
            </Styled.DetailsTitle>
          </NavigationTitle>
        </NavigationCustomElement>
      </Fragment>
    ) : ( */
    <NavigationCustomElement>
      <Styled.DetailsTitle data-testid={identifiers["navbar.detailsTitle"]}>
        <p>{consumer.userFeedbackDetails?.pretty_title}</p>
      </Styled.DetailsTitle>
    </NavigationCustomElement>
  ) : (
    /*  ) */
    <Fragment />
  );

  useCustomElementInjection({
    parentElementId: NAV_LIST,
    element: CustomElement,
    containerId: ELEMENT_CONTAINER_ID,
    tagName: "li",
  });

  if (consumer.error) {
    return (
      <EmptyStateElement
        title="There was an error loading this ticket. Try refreshing."
        subtitle="The link you followed may be broken, or the ticket may have been removed."
        imageSrc={ErrorImage}
      />
    );
  }

  if (!consumer.userFeedbackDetails) {
    return <Spinner />;
  }

  const getAppIntegrations = (
    integrations?: Integration[],
    serviceHooks?: IssueServiceHookPermalink[],
  ): InfoElement[] => {
    if (!integrations) return [];

    return integrations.map((integration) => {
      const serviceHook = serviceHooks?.find((hook) => hook.service_hook_type === integration.type);
      const dropdownProps: IntegrationDropdownProps = {
        integration,
        serviceHook,
        integrationDropdownOpened: consumer.integrationDropdownOpened,
        setIntegrationDropdownOpened: consumer.setIntegrationDropdownOpened,
        sendIssue: consumer.sendIssue,
        sendingIntegrationMap: consumer.sendingIntegrationMap,
        onClickIntegrationSettings: () =>
          navigate(
            generatePath(RoutePaths.ADMINISTRATION_APPS_USER_FEEDBACK, {
              workspaceSlug: selectedWorkspace?.slug,
              appKey: selectedApp?.key,
            }).concat(`?appId=${selectedApp?.id}&type=${integration.type}`),
          ),
      };
      return {
        isError: false,
        tip: `${getIntegrationTypeName(integration.type)} integration`,
        Dropdown: getIntegrationDropdown(dropdownProps),
      } as InfoElement;
    });
  };

  const addToFilterObject = {
    addToFilter: addExternal,
    redirectURL: generatePath(RoutePaths.USER_FEEDBACK, {
      workspaceSlug: selectedWorkspace?.slug,
      appKey: selectedApp?.key,
    }),
  };

  const infoPane = [
    {
      title: "Ticket details",
      infoElements: (
        [
          {
            infoElements: [
              {
                iconComponent: getClockIcon(),
                text: "Activity history",
                subtitle: "Timeline of network traffic, taps and screen visits",
                fullwidth: true,
                onElementClick: () => {
                  navigate(
                    generatePath(RoutePaths.USER_FEEDBACK_LOGS, {
                      workspaceSlug: selectedWorkspace?.slug,
                      appKey: selectedApp?.key,
                      userFeedbackKey: params.userFeedbackKey,
                    }),
                  );
                },
                testId: identifiers["details.pane.activityHistory.button"],
              },
            ],
          },
        ] as (GroupedTicketDetailsElement | undefined)[]
      ).concat(
        getDetails(
          consumer.userFeedbackDetails,
          detailDropdownOpened,
          setDetailDropdownOpened,
          selectedApp,
          addToFilterObject,
          consumer.blackbox,
          consumer.issueDetailsNums,
        ),
      ),
    },
    {
      title: "Session links",
      infoElements: [
        {
          infoElements: [
            {
              tip: consumer.userFeedbackDetails.pretty_fullstory_session ? "Click to visit" : "Fullstory",
              icon: FullstoryIcon,
              fullwidth: false,
              Dropdown: getExtensionsDropdown(
                consumer.userFeedbackDetails.pretty_fullstory_session ? "Fullstory" : "Unavailable",
                "Fullstory",
                FullstoryIcon,
                {
                  text: "Fullstory session",
                  link: consumer.userFeedbackDetails.pretty_fullstory_session,
                },
                isElementDisabled(consumer.userFeedbackDetails.pretty_fullstory_session),
                detailDropdownOpened,
                setDetailDropdownOpened,
              ),
            },
          ],
        },
      ],
    },
    {
      title: "Ticket integrations",
      infoElements: [
        { infoElements: getAppIntegrations(selectedApp?.integrations, consumer.serviceHooks) as InfoElement[] },
      ],
      link: {
        tooltipText: "Add integration",
        link: generatePath(RoutePaths.ADMINISTRATION_APPS_USER_FEEDBACK_KEY, {
          ...params,
          workspaceSlug: selectedWorkspace?.slug,
          appKey: selectedApp?.key,
        }),
        testId: identifiers["details.pane.integration.link.add"],
      },
    },
    {
      title: "Ticket metadata",
      infoElements: [
        {
          infoElements: readMetadata(
            consumer.userFeedbackDetails.metadata_,
            [],
            detailDropdownOpened,
            setDetailDropdownOpened,
            addToFilterObject,
          ),
        },
      ],
      link: {
        tooltipText: "Set ticket metadata",
        link: docLink(selectedApp?.platform.name).addTicketMetadata,
        isExternal: true,
        testId: identifiers["details.pane.metadata.link.add"],
      },
    },
    {
      title: "App user details",
      infoElements: getAppUserInfoElements(consumer.userFeedbackDetails?.app_user, () => {
        navigate(
          generatePath(RoutePaths.USER_DETAILS, {
            workspaceSlug: selectedWorkspace?.slug,
            appKey: selectedApp?.key,
            userId: consumer.userFeedbackDetails?.app_user?.id,
          }),
        );
      }),
      link: {
        tooltipText: "Register app user",
        link: docLink(selectedApp?.platform.name).registerAppUser,
        isExternal: true,
      },
    },
  ] as GroupedInfoElement[];

  return (
    <PageWrap>
      <TicketContainer>
        <TicketInfoContainer>
          <div style={{ marginLeft: "-1.2rem" }}>
            <Heading
              as="h2"
              heading2
              marginBottom={0}
            >
              <EditableTitle
                value={consumer.userFeedbackDetails?.pretty_title}
                defaultValue="No description"
                onEditConfirm={(title?: string) => consumer.updateUserFeedback({ title })}
                testId={identifiers["central.column.user.feedback.title"]}
              />
            </Heading>
          </div>

          {consumer.issueSuggestion &&
            consumer.userFeedbackDetails.suggest_duplicate &&
            !Boolean(consumer.userFeedbackDetails.linked_issues_count) &&
            !consumer.userFeedbackDetails.parent_id && (
              <SmartSuggestion
                suggestion={consumer.issueSuggestion}
                onMerge={() =>
                  consumer.onMergeAction([consumer.userFeedbackDetails as Issue], consumer.issueSuggestion as Issue)
                }
                onRemove={() => consumer.updateFeedbackSuggestion({ suggest_duplicate: false })}
              />
            )}

          <DetailsBar
            details={consumer.userFeedbackDetails}
            changeAssignee={consumer.changeAssignee}
            changePriority={consumer.changePriority}
            changeStatus={consumer.changeStatus}
            deleteTicket={consumer.deleteUserFeedback}
            onMergeAction={consumer.onMergeAction}
            type={RowType.USER_FEEDBACK}
            clearMerge={consumer.onUnMergeAction}
          />
          <InputWithTags
            commonTags={commonTags}
            selectedTags={consumer.userFeedbackDetails.tags}
            onAddTag={consumer.createIssueTag}
            onRemoveTag={consumer.deleteIssueTag}
          />

          <LinkedTicketsTable linkedTickets={consumer.linkedIssues} />

          <CustomFields customFields={consumer.userFeedbackDetails.custom_fields} />
          <AttachmentsContainer attachments={consumer.mappedAttachments.sort((a, b) => a.num - b.num)} />
          {/* Will be uncommented after we are approved */}
          {/*<SentryIntegrationContainer*/}
          {/*  sentryIntegration={selectedWorkspace?.sentry}*/}
          {/*  sentryDataProps={consumer.sentryProjects}*/}
          {/*  fetchSentryIssues={consumer.getSentryIssues}*/}
          {/*  selectedWorkspace={selectedWorkspace}*/}
          {/*/>*/}

          <MessageContainer
            messages={messages}
            onMessageSend={sendMessage}
            onMessageDelete={deleteMessage}
            onMessageEdit={editMessage}
            appUser={consumer.userFeedbackDetails.app_user}
          />
        </TicketInfoContainer>
        <TicketDetailsContainer>
          <DetailsPane
            info={infoPane}
            headerTestId={identifiers["user.feedback.details.pane.header"]}
          />
        </TicketDetailsContainer>
      </TicketContainer>
    </PageWrap>
  );
};
