import * as React from "react";
import { render } from "react-dom";

import * as Sentry from "@sentry/react";

import { startShakeSdk } from "util/shakeSdkUtils";

import App from "./App";

Sentry.init({
  dsn: "https://abf7eeb0980897cce107423115237891@o257007.ingest.sentry.io/4505634614083585",
  environment: process.env.APP_CONFIG_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["^https://dashboard-api.shakebugs.com/"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.5 : 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV !== "development",
});

startShakeSdk();

const rootEl = document.getElementById("root");

render(<App />, rootEl);
